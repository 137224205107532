import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/PrinciplesLayout.js";
import guidelinesPdf from '../../images/Accessibility_Guidelines_UXweb.pdf';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Accessibility Guidelines`}</h1>
    <ul>
      <li parentName="ul">{`For Web accessibility, adhere to `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/WCAG21/"
        }}>{`WCAG 2.1 (Level AA) standards`}</a>{`.`}</li>
      <li parentName="ul">{`For Mobile accessibility, adhere to `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/mobile-accessibility-mapping/"
        }}>{`W3C/WAI Mobile Accessibility Guidelines`}</a>{`.`}</li>
      <li parentName="ul">{`For Multimedia accessibility, adhere to `}<a parentName="li" {...{
          "href": "https://www.w3.org/2008/06/video-notes"
        }}>{`Multimedia Accessibility W3C Policy`}</a>{`.`}</li>
      <li parentName="ul">{`For Authoring Tools Accessibility (such as Software, Content Management Systems (CMS), Courseware Tools, Content Aggregators), follow `}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/standards-guidelines/atag/"
        }}>{`ATAG Guidelines`}</a>{`.`}</li>
      <li parentName="ul">{`For browsers, media players, and other “User Agent” Accessibility, follow `}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/standards-guidelines/uaag/"
        }}>{`UAAG Guidelines`}</a>{`.`}</li>
    </ul>
    <h3>{`Four Principles of Accessibility`}</h3>
    <h5>{`Perceivable`}</h5>
    <p>{`Information and user interface components must
be presentable to users in ways they can perceive
(e.g., alt tags that say what the item actually
does, like ‘Submit form Button’).`}</p>
    <h5>{`Operable`}</h5>
    <p>{`User interface components and navigation must
be operable (e.g., you must be able to navigate
the site using a keyboard as well as a mouse).`}</p>
    <h5>{`Understandable`}</h5>
    <p>{`Information and the operation of user interface
must be understandable, (e.g., error messaging
on a form should make sense; instead of ‘Invalid
field’ messaging, use ‘The Email field must be
in a valid format’).`}</p>
    <h5>{`Robust`}</h5>
    <p>{`Content must be robust enough so it can be
interpreted reliably by a wide variety of user
agents, including assistive technologies. In
other words, don’t use tags or code that only
certain browsers understand.`}</p>
    <h3>{`Guidelines Checklist`}</h3>
    <ul>
      <li parentName="ul">
        <h5 parentName="li">{`Text Alternatives`}</h5>
        <p parentName="li">{`  Provide alternatives for non-text content
(e.g., images) so that the content is
accessible for all users.`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Time-Based Media`}</h5>
        <p parentName="li">{`  Provide an alternative (e.g., transcript)
for time-based media (e.g., audio/video) that
presents equivalent information, or link to
textual information with comparable information
for non-prerecorded media).`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Adaptable`}</h5>
        <p parentName="li">{`  Create content that can be presented in different
ways without losing information or structure.`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Distinguishable`}</h5>
        <p parentName="li">{`  Make it easy for users to see and hear content,
including separating foreground and background,
by using readable fonts, larger font sizes, and
highlighted link styling for example.`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Keyboard Accessible`}</h5>
        <p parentName="li">{`  Make all functionality available from a keyboard.`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Timing`}</h5>
        <p parentName="li">{`  Provide enough time for users to read and use content.`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Seizures`}</h5>
        <p parentName="li">{`  Do not include design elements that are known to
cause seizures (e.g., rapid flashing).`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Navigable`}</h5>
        <p parentName="li">{`  Provide multiple ways to allow users to navigate
content including obvious/prominent links and
other techniques.`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Readable`}</h5>
        <p parentName="li">{`  Make text content readable and understandable via styling and other
techniques. Consider the accessibility best practices of certain text
symbols before using them in text content. For example, avoid ampersands
in order to prevent conflict with screen readers.`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Predictable`}</h5>
        <p parentName="li">{`  Make web pages appear and operate in predictable ways.`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Input Assistance`}</h5>
        <p parentName="li">{`  Assist users with web experience, correct mistakes
and describe errors in text.`}</p>
      </li>
      <li parentName="ul">
        <h5 parentName="li">{`Compatible`}</h5>
        <p parentName="li">{`  Maximize compatibility with current and future
user agents, including assistive technologies.`}</p>
      </li>
    </ul>
    <a href={guidelinesPdf}>(PDF link) A Checklist for UX and Web Designers</a>
    <h3>{`Resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.section508.gov/"
        }}>{`Section 508 Standards`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.usability.gov/"
        }}>{`Usability Standards`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      